//
// Label for forms and fields
//
const Label = ({ label = "", inputName = "" }) => (
  <label className="control-label" htmlFor={inputName} id={`${inputName}_label`}>
    {label}
  </label>
);

export default Label;
