const HelpBlock = ({ text }) => {
  if (!text || text.length === 0) return null;
  return (
    <span className="help-block">
      <i className="fa fa-info-circle" />
      &nbsp;
      {text}
    </span>
  );
};

export default HelpBlock;
