import { fromJS, List, Set } from "immutable";
import PropTypes from "prop-types";
import { Component } from "react";

import { emptyFunc, emptyList } from "app/utils/constants";

import MultiButtonSelect from "app/common/components/MultiButtonSelect";
import ErrorList from "./ErrorList";
import Label from "./Label";
import HelpBlock from "./HelpBlock";
import { debounce } from "lodash";
import { getFormClassNames } from "app/common/forms/utils";

class MultiButtonSelectField extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value };
    this.debouncedOnInputChange = props.onInputChange ? debounce(props.onInputChange, 500) : null;
  }

  handleChangeButtonSelect = (selectedItems) => {
    const newValue = fromJS(selectedItems).toList();
    this.handleChange(newValue);
  };

  handleChange = (newValue) => {
    const { nullWhenEmpty, options } = this.props;
    const mandatoryOptions = fromJS(options)
      .filter((item) => !item.get("clearableValue", true))
      .map((item) => item.get("value"));
    let updatedValue = Set(newValue).union(mandatoryOptions).toList();
    if (updatedValue.size === 0 && nullWhenEmpty) {
      updatedValue = null;
    }
    this.setState({ value: updatedValue }, () => this.changeCallbacks(updatedValue));
  };

  changeCallbacks = (value) => {
    const { onChange, onFieldChange, name } = this.props;

    onChange({ [name]: value });
    onFieldChange(value);
  };

  // Render
  render() {
    const { name, help, errors, label, options } = this.props;
    const { value, overrideChecked } = this.state;
    const valueJS = value ? value.toJS() : [];

    const inputName = `${name}_input`;
    const inputElement = (
      <div>
        <MultiButtonSelect
          value={valueJS}
          options={options}
          disabled={overrideChecked}
          onChange={this.handleChangeButtonSelect}
        />
      </div>
    );

    const LabelElement = this.props.labelElement || Label;
    const ErrorListElement = this.props.errorListElement || ErrorList;

    const labelElement = label && <LabelElement inputName={inputName} label={label} />;
    const helpElement = help && <HelpBlock text={help} />;
    const className = getFormClassNames(errors);

    return (
      <div className={className}>
        {labelElement}
        {inputElement}
        {helpElement}
        <ErrorListElement errors={errors} />
      </div>
    );
  }
}

MultiButtonSelectField.defaultProps = {
  errors: emptyList,
  value: emptyList,

  onFieldChange: emptyFunc,

  nullWhenEmpty: false,
};
MultiButtonSelectField.propTypes = {
  errors: PropTypes.instanceOf(List),
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(List),
  value: PropTypes.instanceOf(List),

  onChange: PropTypes.func,
  onFieldChange: PropTypes.func,

  // Return null instead of empty array when nothing selected.
  nullWhenEmpty: PropTypes.bool,
};

export default MultiButtonSelectField;
