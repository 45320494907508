import { Icons } from "app/utils/constants";

export const debugTenantItems = [
  {
    title: "Debug",
    children: [
      {
        icon: Icons.home,
        title: "Dashboard",
        route: "/debug",
      },
      {
        icon: Icons.settings,
        title: "Tests",
        route: "/debug/tests",
      },
      {
        icon: Icons.calculation,
        title: "Berekeningen",
        route: "/debug/calc",
      },
      {
        icon: Icons.snapshot,
        title: "Snapshots",
        route: "/debug/snapshots",
      },
    ],
  },
];
