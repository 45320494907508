//
// Main frontend bundle entry point
//
import * as React from "react";
import { debugWhyDidYouRender } from "app/process";

// TODO we could possibly do this in initialiseApp?
if (debugWhyDidYouRender) {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

// NOTE Babel polyfill should be on top of the first entry loaded!!
// import '@babel/polyfill';
import initialiseApp from "app/core/init";

// Actually intialising app
const elementId = "app";
initialiseApp(elementId);
