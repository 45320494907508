import { fromJS, Map } from "immutable";
import moment from "app/utils/momentLocalized";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes as usersActionTypes } from "app/features/users/actions";
import { emptyMap, isLoadingMap } from "app/utils/constants";
import { arrayToPkMap } from "app/utils/immutableUtils";
import { AssignmentDetailedRecord, ColorSlotRecord, ProjectSimpleRecord, WorkDetailedRecord } from "app/utils/records";
import { actionTypes } from "./actions";
import { getDateRangeKey } from "./utils";
import { TimelineViewRecord } from "app/features/timelines/components/TimelineView";

export const defaultIntervalType = "workweek";
export const defaultPanelOption = "workResources";

const initialState = fromJS({
  planBoardUrl: null,
  planBoardView: TimelineViewRecord(),

  selectedItem: null,

  panelOption: defaultPanelOption,
  showEmptyTimelinesResources: true,
  showEmptyTimelinesWorks: true,
  showEmptyTimelinesWorksIfFullyPlanned: true,

  actualDateRanges: emptyMap,
  actualPlanBoardView: TimelineViewRecord(),
});

/***
 * Sets new interval and center date for the plan board.
 * Also correctly sets calculated start/end dates.
 * @param state
 * @param currentDate
 * @param intervalType Optional new value for intervalType.
 * If omitted, leaves intervalType unchanged.
 * @returns {*} A new state object
 */
function derivePlanBoardView(state, currentDate, intervalType) {
  // Jumping to "date" shows the current interval (week, year).
  // We must always set the startDate to a correct interval start.
  intervalType = intervalType || state.getIn(["planBoardView", "intervalType"]) || defaultIntervalType;

  // Validate interval type
  if (["day", "week", "workweek", "month", "year"].indexOf(intervalType) === -1) {
    throw new Error("Unknown interval type ", intervalType);
  }

  // Depending on locale, startOf('week') could be a Sunday or a Monday.
  // Let's keep it ISO, always Monday.
  if (intervalType === "week") {
    intervalType = "isoweek";
  }
  const startDate = moment(currentDate);
  let endDate;
  // Moment doesn't know about workweeks, so we set the endDate to Friday manually
  if (intervalType === "workweek") {
    startDate.startOf("isoweek");
    endDate = moment(currentDate).isoWeekday(6).startOf("day");
  } else {
    // NOTE this only works for views that end at a day! (not for custom views)
    startDate.startOf(intervalType);
    endDate = moment(currentDate).endOf(intervalType).startOf("day").add(1, "day");
  }

  return TimelineViewRecord({
    currentDate,
    startDate,
    endDate,
    intervalType,
  });
}

function setPlanBoardViewInState(state, planBoardView, key = "planBoardView") {
  return state.set(key, planBoardView);
}

//
// Handlers
//

export function handleNavToActualDate(state, action) {
  const currentDate = action.payload;
  const planBoardView = derivePlanBoardView(state, currentDate);
  return setPlanBoardViewInState(state, planBoardView, "actualPlanBoardView");
}

export function handleSetPlanBoardPanelOption(state, action) {
  return state.set("panelOption", action.payload);
}

export function handleToggleEmptyTimelinesResources(state, action) {
  return state.set("showEmptyTimelinesResources", action.payload);
}

export function handleToggleEmptyTimelinesWorks(state, action) {
  return state.set("showEmptyTimelinesWorks", action.payload);
}

export function handleToggleEmptyTimelinesWorksIfFullyPlanned(state, action) {
  return state.set("showEmptyTimelinesWorksIfFullyPlanned", action.payload);
}

export function handleSelectItem(state, action) {
  const selectedItem = action.payload;

  const noItemOrAlreadySelected = !selectedItem || selectedItem.get("key") === state.getIn(["selectedItem", "key"]);
  const nextSelectedItem = noItemOrAlreadySelected ? null : selectedItem;

  return state.set("selectedItem", nextSelectedItem);
}

function handlePlanBoardLoadUserProfile(state, action) {
  if (action.status === "done") {
    const result = action.payload.body;

    state = handleSetPlanBoardPanelOption(state, { payload: result.planboard_panels });
    state = handleToggleEmptyTimelinesResources(state, { payload: result.planboard_empty_resources });
    state = handleToggleEmptyTimelinesWorks(state, { payload: result.planboard_empty_work });
    state = handleToggleEmptyTimelinesWorksIfFullyPlanned(state, {
      payload: result.planboard_empty_work_if_fully_planned,
    });
  }

  return state;
}

function handleSetPlanBoardUrl(state, action) {
  return state.set("planBoardUrl", action.payload);
}

function handleSetPlanBoardView(state, action) {
  const planBoardView = action.payload;
  return setPlanBoardViewInState(state, planBoardView);
}

function handleClearPlanBoardView(state, action) {
  return setPlanBoardViewInState(state, TimelineViewRecord());
}

function handleLoadActualDateRange(state, action) {
  const { start, end } = action.data;
  const dateRangeKey = getDateRangeKey(start, end);

  if (action.status === "pending") {
    state = state.setIn(["actualDateRanges", dateRangeKey], isLoadingMap);
  } else if (action.status === "done") {
    // TODO else it was probably "cached"
    const dateRangeData = action.payload.body;
    if (dateRangeData) {
      const { projects, works, assignments, color_slots: colorSlots, notes } = dateRangeData;

      const projectsMap = arrayToPkMap(projects.map(ProjectSimpleRecord));
      const worksMap = arrayToPkMap(works.map(WorkDetailedRecord));
      // TODO
      //  This is an ugly workaround, this happens because assignment names might be stale when work name has
      //  changed. We should fix this in the backend.
      const assignmentsMap = arrayToPkMap(
        assignments
          .map((a) => ({
            ...a,
            name: a.work ? worksMap.getIn([`${a.work}`, "name"]) : a.name,
          }))
          .map(AssignmentDetailedRecord),
      );

      const dateRange = fromJS(
        Map({
          assignments: assignmentsMap,
          notes,
          projects: projectsMap,
          works: worksMap,
          colorSlots: colorSlots.map(ColorSlotRecord),
        }).toJS(),
      );

      state = state.setIn(["actualDateRanges", dateRangeKey], dateRange);
    }
  }

  return state;
}

function handleLoadActualDateRangeNotes(state, action) {
  const { start, end } = action.data;
  const dateRangeKey = getDateRangeKey(start, end);

  if (action.status === "pending") {
    // TODO handle properly
  } else if (action.status === "done") {
    state = state.setIn(["actualDateRanges", dateRangeKey, "notes"], fromJS(action.payload.body.notes));
  }

  return state;
}

function handleClearActualDateRanges(state, action) {
  return state.set("actualDateRanges", emptyMap);
}

function handleClearActualDateRangesExceptCurrent(state, action) {
  const startDate = moment(state.getIn(["actualPlanBoardView", "startDate"]));
  const endDate = moment(state.getIn(["actualPlanBoardView", "endDate"]));
  const currentKey = getDateRangeKey(startDate, endDate);
  return state.set(
    "actualDateRanges",
    state.get("actualDateRanges").filter((_, key) => key === currentKey),
  );
}

function setActualViewInState(state, planBoardView) {
  return setPlanBoardViewInState(state, planBoardView, "actualPlanBoardView");
}

function handleClearActualView(state, action) {
  return setActualViewInState(state, TimelineViewRecord());
}

function handleSetActualView(state, action) {
  const planBoardView = action.payload;
  return setActualViewInState(state, planBoardView);
}

//
// Reducer
//

function planBoardReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [usersActionTypes.LOAD_USER_PROFILE]: handlePlanBoardLoadUserProfile,

    [actionTypes.CLEAR_PLANBOARD_VIEW]: handleClearPlanBoardView,
    [actionTypes.SELECT_ITEM]: handleSelectItem,
    [actionTypes.SET_PLANBOARD_URL]: handleSetPlanBoardUrl,
    [actionTypes.SET_PLANBOARD_VIEW]: handleSetPlanBoardView,
    [actionTypes.SET_PLANBOARD_PANEL_OPTION]: handleSetPlanBoardPanelOption,

    [actionTypes.TOGGLE_EMPTY_TIMELINES_RESOURCES]: handleToggleEmptyTimelinesResources,
    [actionTypes.TOGGLE_EMPTY_TIMELINES_WORKS]: handleToggleEmptyTimelinesWorks,
    [actionTypes.TOGGLE_EMPTY_TIMELINES_WORKS_IF_FULLY_PLANNED]: handleToggleEmptyTimelinesWorksIfFullyPlanned,

    // TODO
    //  This is a temp workaround, we should somehow work with planningIds such that we can keep the views of
    //  the different plannings. (i.e. calc:calcId, snapshot:snapshotId, actual:scopeId, etc.)
    // Related to actualPlanning
    [actionTypes.CLEAR_ACTUAL_DATE_RANGES]: handleClearActualDateRanges,
    [actionTypes.CLEAR_ACTUAL_DATE_RANGES_EXCEPT_CURRENT]: handleClearActualDateRangesExceptCurrent,
    [actionTypes.CLEAR_ACTUAL_VIEW]: handleClearActualView,
    [actionTypes.LOAD_ACTUAL_DATE_RANGE]: handleLoadActualDateRange,
    [actionTypes.LOAD_ACTUAL_DATE_RANGE_NOTES]: handleLoadActualDateRangeNotes,
    [actionTypes.NAV_TO_ACTUAL_DATE]: handleNavToActualDate,
    [actionTypes.SET_ACTUAL_VIEW]: handleSetActualView,
  });
}

export default planBoardReducer;
