import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { fromJS, List, Map, OrderedSet, Set } from "immutable";
import classNames from "classnames";

export const emptyList = List();
export const emptyMap = Map();
export const emptySet = Set();
export const emptyOrderedSet = OrderedSet();

// TODO extract
export const emptyResourceFilter = fromJS({
  query: "",
  breeds: emptySet,
  labels: emptySet,
  resources: emptySet,
  assignmentLabels: emptySet,
  assignmentProjects: emptySet,
  assignmentWorks: emptySet,
});

export const emptyFunc = () => {};
export const nullFunc = () => null;
export const trueFunc = () => true;
export const falseFunc = () => false;
export const identityFunc = (x) => x;

export const isLoadingKey = "isLoading";
export const isLoadingMap = fromJS({ [isLoadingKey]: true });
export const notLoadedKey = "notLoaded";
export const notLoadedMap = fromJS({ [notLoadedKey]: true });

export const isMapNotLoaded = (o) => o && o.get(notLoadedKey);
export const isMapLoading = (o) => o?.get(isLoadingKey);
export const isMapNotReady = (o) => !o || isMapNotLoaded(o) || isMapLoading(o);
export const isMapReady = (o) => !isMapNotReady(o);

export const areMapsLoading = (l) => l.some(isMapLoading);
export const areMapsNotReady = (l) => l.some(isMapNotReady);

export const getIconClassName = (icon) => `fa fa-${icon}`;

export const CustomIcon = ({ icon, style, className }) => (
  <i className={classNames([getIconClassName(icon), className])} style={style} />
);

export const CustomIconWithLabel = ({ icon, style, className, label }) => (
  <>
    <CustomIcon icon={icon} style={style} className={className} />
    &nbsp;{label}
  </>
);

// TODO
//  - complement other itemTypes
//  - all these static get methods are very ugly
export class ItemTypes {
  static get assignment() {
    return "assignment";
  }

  static get assignmentBlock() {
    return "assignmentBlock";
  }

  static get calendarEvent() {
    return "calendarEvent";
  }

  static get colorSlot() {
    return "colorSlot";
  }

  static get deactivatedSlot() {
    return "deactivatedSlot";
  }

  static get feasibleSlot() {
    return "feasibleSlot";
  }

  static get entity() {
    return "entity";
  }

  static get planningNote() {
    return "planningNote";
  }

  static get project() {
    return "project";
  }

  static get resource() {
    return "resource";
  }

  static get resourceChangeover() {
    return "resourceChangeover";
  }

  static get resourceClaim() {
    return "resourceClaim";
  }

  static get resourceTravel() {
    return "resourceTravel";
  }

  static get stockItem() {
    return "stockItem";
  }

  static get storage() {
    return "storage";
  }

  static get work() {
    return "work";
  }

  static get workGroup() {
    return "workGroup";
  }

  static get heatSlot() {
    return "heatSlot";
  }

  static get breakingSlot() {
    return "breakingSlot";
  }

  static get freeSlot() {
    return "freeSlot";
  }
}

export class Icons {
  static get home() {
    return "house";
  }

  static get link() {
    return "link";
  }

  static get unlink() {
    return "unlink";
  }

  static get clean() {
    return "broom";
  }

  static get hide() {
    return "eye-slash";
  }

  static get show() {
    return "eye";
  }

  static get label() {
    return "tags";
  }

  static get download() {
    return "download";
  }

  static get fileUpload() {
    return "file-upload";
  }

  static get check() {
    return "check";
  }

  static get pause() {
    return "pause";
  }

  static get play() {
    return "play";
  }

  static get add() {
    return "plus";
  }

  static get remove() {
    return "minus";
  }

  static get expand() {
    return "plus";
  }

  static get collapse() {
    return "minus";
  }

  static get calculation() {
    return "calculator";
  }

  static get clear() {
    return "times";
  }

  static get clone() {
    return "copy";
  }

  static get lock() {
    return "lock";
  }

  static get unlock() {
    return "lock-open";
  }

  static get exception() {
    return "etsy";
  }

  static get delete() {
    return "trash";
  }

  static get edit() {
    return "pencil";
  }

  static get planboard() {
    return "calendar";
  }

  static get actualPlanning() {
    return "calendar";
  }

  static get publishedPlanning() {
    return "calendar-check";
  }

  static get manage() {
    return "cog";
  }

  static get note() {
    return "sticky-note";
  }

  static get settings() {
    return "cog";
  }

  static get work() {
    return "list-ul";
  }

  static get changelog() {
    return "list";
  }

  static get project() {
    return "list-ul";
  }

  static get skill() {
    return "list-ul";
  }

  static get resource() {
    return "cog";
  }

  static get resourceHuman() {
    return "user";
  }

  static get resourceBreed() {
    return "cog";
  }

  static get stock() {
    return "box";
  }

  static get storage() {
    return "warehouse";
  }

  static get network() {
    return "link";
  }

  static get back() {
    return "long-arrow-left";
  }

  static get save() {
    return "floppy-o";
  }

  static get copy() {
    return "copy";
  }

  static get block() {
    return "th";
  }

  static get calendars() {
    return "calendar-o";
  }

  static get skills() {
    return "wrench";
  }

  static get resources() {
    return "users";
  }

  static get resourceChangeovers() {
    return "users-cog";
  }

  static get user() {
    return "user-circle-o";
  }

  static get tenant() {
    return "building";
  }

  static get tenants() {
    return "city";
  }

  static get profile() {
    return "user";
  }

  static get logout() {
    return "sign-out";
  }

  static get location() {
    return "map-pin";
  }

  static get realised() {
    return "clock";
  }

  static get refresh() {
    return "refresh";
  }

  static get warning() {
    return "exclamation-triangle";
  }

  static get snapshot() {
    return "clock";
  }

  static get capacity() {
    return "thermometer-half";
  }

  static get report() {
    return "chart-pie";
  }

  static get webSocket() {
    return "plug";
  }

  static get chevronUp() {
    return "chevron-up";
  }

  static get chevronDown() {
    return "chevron-down";
  }
  static get chevronRight() {
    return "chevron-right";
  }

  static get arrowRight() {
    return "arrow-right";
  }
}

export const colorChoiceMessages = defineMessages({
  green: {
    id: "colorChoices.green",
    defaultMessage: "Green",
  },
  orange: {
    id: "colorChoices.orange",
    defaultMessage: "Orange",
  },
  red: {
    id: "colorChoices.red",
    defaultMessage: "Red",
  },
});

export const useColorChoices = () => {
  const { formatMessage } = useIntl();
  return useMemo(
    () => [
      {
        value: "green",
        label: formatMessage(colorChoiceMessages.green),
      },
      {
        value: "orange",
        label: formatMessage(colorChoiceMessages.orange),
      },
      {
        value: "red",
        label: formatMessage(colorChoiceMessages.red),
      },
    ],
    [formatMessage],
  );
};

export const useColorChoicesWithoutOrange = () => {
  const { formatMessage } = useIntl();
  return useMemo(
    () => [
      {
        value: "green",
        label: formatMessage(colorChoiceMessages.green),
      },
      {
        value: "red",
        label: formatMessage(colorChoiceMessages.red),
      },
    ],
    [formatMessage],
  );
};

export const precedenceTypeChoiceMessages = defineMessages({
  end_after_end: {
    id: "PrecedenceTypeChoices.end_after_end",
    defaultMessage: "Successor ends after Predecessor ends",
  },
  start_after_end: {
    id: "PrecedenceTypeChoices.start_after_end",
    defaultMessage: "Successor starts after Predecessor ends",
  },
  start_after_start: {
    id: "PrecedenceTypeChoices.start_after_start",
    defaultMessage: "Successor starts after Predecessor starts",
  },
});

export const precedenceTypeChoices = (formatMessage) => [
  {
    value: "start_after_end",
    label: formatMessage(precedenceTypeChoiceMessages.start_after_end),
  },
  {
    value: "start_after_start",
    label: formatMessage(precedenceTypeChoiceMessages.start_after_start),
  },
  {
    value: "end_after_end",
    label: formatMessage(precedenceTypeChoiceMessages.end_after_end),
  },
];
