//
// Application content wrapper
//
import AppContentSwitch from "app/core/components/AppContentSwitch";

const contentWrapperStyle = {
  height: "100%",
  width: "100%",
  borderLeft: "1px solid rgba(0, 0, 0, 0.05)",
  background: "#e4e4e4",
  overflowY: "auto",
};

export const AppContentWrapper = ({ children }) => (
  <div style={contentWrapperStyle}>
    <div className="content content-full">{children}</div>
  </div>
);

const AppContent = () => (
  <AppContentWrapper>
    <AppContentSwitch />
  </AppContentWrapper>
);

export default AppContent;
